import { createApi } from '@reduxjs/toolkit/query/react';
import ListRequest from '../../../../types/ListRequest';
import ListResponse, {
  NullableListResponse
} from '../../../../types/ListResponse';
import {
  Appointment,
  DashboardPortCall,
  Evaluation,
  PagingRequest
} from '../../types';
// todo investigate why we can not import from barrel
import { axiosBaseQuery, isBaseQueryError } from '@client';
import { AppointmentRecipientResponse } from '@module/appointment/model/AppointmentRecipientResponse';
import { AppointmentResponse } from '@module/appointment/model/AppointmentResponse';
import { DashboardHistoryActionDTO } from '@module/dashboard/model/DashboardHistoryAction';
import { PortCallResponse } from '@module/port-call/model/PortCallResponse';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { AssignEvaluationDTO } from '../../types';
import { EvaluationVerificationDTO } from './EvaluationVerificationDTO';
import { buyerDashboardSlice } from './reducers';
import { PortCallWithAppointment } from '../../types';
import { PortCallTableItem } from '@module/port-call/model/PortCallTableItem';

interface FetchPortCallsRequest extends ListRequest {
  //
}

export const portCallsAdapter = createEntityAdapter<DashboardPortCall>();
export const appointmentsAdapter = createEntityAdapter<Appointment>();
export const evaluationAdapter = createEntityAdapter<Evaluation>();

export const portCallsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['PortCalls', 'History', 'PortCallWithAppointment'],
  reducerPath: 'port-calls_api',
  endpoints: (build) => ({
    fetchPortCalls: build.query<
      ListResponse<DashboardPortCall>,
      FetchPortCallsRequest
    >({
      query: ({ page, pageSize, filter }) => ({
        url: `secured/dashboard/portCalls`,
        method: 'GET',
        params: {
          page: page,
          size: pageSize,
          ...filter
        }
      }),
      transformResponse: (
        response: NullableListResponse<DashboardPortCall>
      ) => {
        return { ...response, items: response.items ?? [] };
      }
    }),
    fetchPortCall: build.query<PortCallResponse, { id: number }>({
      query: ({ id }) => ({
        url: `secured/port-call/${id}/load`,
        method: 'GET'
      })
    }),
    updatePortCall: build.mutation<
      PortCallResponse,
      Partial<DashboardPortCall> & Pick<DashboardPortCall, 'id'>
    >({
      query: (data) => ({
        url: `/secured/port-call/save-details`,
        method: 'POST',
        data
      }),
      transformErrorResponse: (error) => {
        return isBaseQueryError(error)
          ? error.data.message
          : 'Error while fetching/updating port call details';
      },
      async onQueryStarted(data, { dispatch, queryFulfilled, getState }) {
        // @ts-ignore
        const state = getState() as StateWithBuyerDashboard;
        // need these to match the query cache key so that we can update the cache below
        const paginationState = state[buyerDashboardSlice.name].pagination;

        try {
          const {
            data: { id, schedule, portName, countryName, sailingDate }
          } = await queryFulfilled;

          dispatch(
            portCallsApi.util.updateQueryData(
              'fetchPortCalls',
              { ...paginationState },
              ({ items, totalItems }) => ({
                totalItems,
                items: items.map((item) => {
                  if (item.id === id) {
                    return {
                      ...item,
                      eta: schedule.dateIn,
                      ets: schedule.dateOut,
                      sld: sailingDate,
                      port: portName,
                      country: countryName
                    };
                  }
                  return item;
                })
              })
            )
          );
        } catch {
          //
        }
      }
    }),
    sendReminder: build.mutation<
      AppointmentResponse,
      { appointmentId: number; portCallId: number } & ListRequest
    >({
      query: ({ appointmentId }) => ({
        url: `/secured/appointment/${appointmentId}/send-reminder`,
        method: 'POST'
      }),
      async onQueryStarted(
        { appointmentId, portCallId, ...pagination },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          portCallsApi.util.updateQueryData(
            'fetchPortCalls',
            pagination,
            (draft) => {
              const appointment = draft.items
                .find((i) => i.id === portCallId)
                ?.appointments?.find((a) => a.id === appointmentId);

              if (appointment) {
                const { firstPokeDate, pokes, pokeLock } = appointment.observer;
                appointment.observer = {
                  pokeLock,
                  firstPokeDate: firstPokeDate
                    ? firstPokeDate
                    : new Date().toUTCString(),
                  lastPokeDate: new Date().toISOString(),
                  pokes: (pokes ?? 0) + 1,
                  state: 'UNREAD'
                };
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    fetchAppointmentRecipient: build.query<
      AppointmentRecipientResponse,
      number
    >({
      query: (appointmentId) => ({
        url: `/secured/appointments/${appointmentId}/appointment-recipient`,
        method: 'GET'
      })
    }),
    fetchFirstLevelEvaluationFlowDepartments: build.query<
      EvaluationVerificationDTO[],
      string
    >({
      query: (evaluationIds) => ({
        url: `/secured/evaluations/active-flow-step?evaluationIds=${evaluationIds}`,
        method: 'GET'
      })
    }),
    fetchAppointmentHistory: build.query<DashboardHistoryActionDTO[], number>({
      query: (appointmentId) => ({
        url: `/secured/dashboard/history/${appointmentId}`,
        method: 'GET'
      }),
      providesTags: (_result, _error, appointmentId) => [
        {
          type: 'History',
          id: appointmentId
        }
      ]
    }),
    assignEvaluation: build.mutation<{}, AssignEvaluationDTO>({
      query: (data) => ({
        url: `/secured/evaluation/assign`,
        method: 'POST',
        data
      }),
      async onQueryStarted(
        {
          portCallId,
          appointmentId,
          pagination,
          evaluationId,
          assign,
          profile
        },
        { dispatch, queryFulfilled }
      ) {
        // optimistic updates:
        // https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
        const patchResult = dispatch(
          portCallsApi.util.updateQueryData(
            'fetchPortCalls',
            pagination,
            (draft) => {
              const {
                id,
                fullName,
                role,
                department: { id: value, name: label }
              } = profile;

              const evaluation = draft.items
                .find((i) => i.id === portCallId)
                ?.appointments?.find((a) => a.id === appointmentId)
                ?.evaluations?.find((e) => e.id === evaluationId);

              if (evaluation) {
                evaluation.assigneeId = assign ? profile.id : null;
                evaluation.assignee = assign
                  ? { department: { label, value }, fullName, id, role }
                  : null;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_result, _error, { appointmentId }) => [
        { type: 'History', id: appointmentId }
      ]
    }),
    fetchPortcallWithAppointment: build.query<
      PortCallWithAppointment | null,
      { portcallId: number }
    >({
      query: ({ portcallId }) => ({
        url: `/secured/port-calls-with-appointment/${portcallId}`,
        method: 'GET'
      }),
      providesTags: ['PortCallWithAppointment']
    }),
    portcallChangeVoyage: build.mutation<
      PortCallWithAppointment | null,
      { portcallId: number; voyageId: number }
    >({
      query: (data) => ({
        url: `/secured/port-call-change-voyage`,
        method: 'POST',
        data
      }),
      invalidatesTags: ['PortCallWithAppointment']
    }),
    fetchImportedPortCalls: build.query<
      { items: PortCallTableItem[] },
      PagingRequest
    >({
      query: (pageRequest) => ({
        url: `/secured/buyer/da/dashboard/port-calls/imported`,
        method: 'POST',
        data: pageRequest
      })
    })
  })
});

export type StateWithPortCallsApi = {
  [portCallsApi.reducerPath]: ReturnType<typeof portCallsApi.reducer>;
};

export const {
  useLazyFetchPortcallWithAppointmentQuery,
  useFetchPortCallsQuery,
  useLazyFetchPortCallQuery,
  useUpdatePortCallMutation,
  useSendReminderMutation,
  useLazyFetchAppointmentRecipientQuery,
  useFetchAppointmentRecipientQuery,
  useLazyFetchFirstLevelEvaluationFlowDepartmentsQuery,
  useFetchFirstLevelEvaluationFlowDepartmentsQuery,
  useFetchAppointmentHistoryQuery,
  useLazyFetchAppointmentHistoryQuery,
  useAssignEvaluationMutation,
  endpoints,
  usePortcallChangeVoyageMutation,
  useLazyFetchImportedPortCallsQuery
} = portCallsApi;

export type fetchPortCallsResponse =
  typeof portCallsApi.endpoints.fetchPortCalls.Types.ResultType;
