import { createApi } from '@reduxjs/toolkit/query/react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { axiosBaseQuery } from '@client';
import { RootState } from '../../store';
import { ConversationResponse } from './models/ConversationResponse';
import { PagingRequest } from 'components';
import ListResponse from '../../../../types/ListResponse';
import { CountMessagesResponse } from './models/CountMessagesResponse';
import { ConversationMessageReferenceResponse } from './models/ConversationMessageReferenceResponse';
import { conversationsSlice } from './reducers';
import { MessageRecipientResponse } from '@module/messaging/model/MessageRecipientResponse';

const updateConversation = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  conversationId: string,
  buyerId: string | undefined,
  updateRecipe: Recipe<ConversationResponse>
) => {
  dispatch(
    conversationApi.util.updateQueryData(
      'fetchConversation',
      { conversationId, buyerId },
      updateRecipe
    )
  );
};

const updateUnreadMessagesCountCache = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  updateRecipe: Recipe<CountMessagesResponse>
) => {
  dispatch(
    conversationApi.util.updateQueryData(
      'fetchUnreadMessagesCount',
      undefined,
      updateRecipe
    )
  );
};

const reFetchConversations = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  getState: () => RootState
) => {
  const {
    [conversationsSlice.name]: { pagination, drawerOpened }
  } = getState();

  if (pagination.filter !== 'READ' && drawerOpened) {
    const paginationObject = {
      page: 0,
      size: pagination.pageSize * (pagination.page + 1),
      filter:
        pagination.filter !== 'ALL'
          ? {
              singleValueFilterItems: [
                {
                  name: 'status',
                  value: pagination.filter
                }
              ],
              arrayFilterItems: []
            }
          : undefined
    };
    dispatch(
      conversationApi.endpoints.fetchConversations.initiate(paginationObject, {
        forceRefetch: true
      })
    );
  }
};

export const conversationApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Conversation'],
  reducerPath: 'conversation_api',
  endpoints: (build) => ({
    subscribeToChatMessages: build.query<
      { received: ConversationMessageReferenceResponse | undefined },
      void
    >({
      queryFn: () => {
        return new Promise((resolve) => {
          resolve({
            data: { received: undefined }
          });
        });
      }
    }),
    fetchConversationMessageReference: build.query<
      ConversationMessageReferenceResponse,
      { messageId: number }
    >({
      query: ({ messageId }) => ({
        url: `/secured/message/${messageId}/load`,
        method: 'GET'
      })
    }),
    fetchConversations: build.query<
      ListResponse<MessageRecipientResponse>,
      PagingRequest
    >({
      query: (pagingRequest) => ({
        url: `/secured/messages/list`,
        method: 'POST',
        data: pagingRequest
      }),
      // change the cache key to ignore page
      serializeQueryArgs: ({ queryArgs }) => {
        const { page, ...others } = queryArgs;
        const pagingRequest = { ...others, page: 0 };
        return pagingRequest;
      },
      // merge incoming items with cached
      merge: (currentCache, newResponse, { arg }) => {
        if (currentCache && arg.page > 0) {
          return {
            ...newResponse,
            items: [...currentCache.items, ...newResponse.items]
          };
        }
        return newResponse;
      }
    }),
    fetchUnreadMessagesCount: build.query<CountMessagesResponse, void>({
      query: () => ({
        url: `/secured/message/unread`,
        method: 'GET'
      })
    }),
    fetchConversation: build.query<
      ConversationResponse,
      { conversationId: string; buyerId: string | undefined }
    >({
      query: ({ conversationId, buyerId }) => ({
        url: `/secured/conversation/${conversationId}/load`,
        method: 'GET',
        params: {
          buyerId
        }
      })
    }),
    markAllMessagesRead: build.mutation<MessageRecipientResponse[], void>({
      query: () => ({
        url: `/secured/message/read-all`,
        method: 'GET'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;

          reFetchConversations(dispatch, getState as () => RootState);

          updateUnreadMessagesCountCache(dispatch, (draft) => {
            draft.count = 0;
          });
        } catch {
          //
        }
      }
    }),
    markMessageRead: build.mutation<
      MessageRecipientResponse,
      { messageId: number }
    >({
      query: ({ messageId }) => ({
        url: `/secured/message/read`,
        method: 'POST',
        data: messageId.toString()
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;

          reFetchConversations(dispatch, getState as () => RootState);

          updateUnreadMessagesCountCache(dispatch, (draft) => {
            draft.count = draft.count - 1;
          });
        } catch {
          //
        }
      }
    }),
    sendMessage: build.mutation<
      ConversationResponse,
      { conversationId: string; data: FormData; buyerId: string | undefined }
    >({
      query: ({ conversationId, data, buyerId }) => ({
        url: `/secured/conversation/${conversationId}/message/send`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data,
        params: {
          buyerId
        }
      }),
      async onQueryStarted({ buyerId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          updateConversation(
            dispatch,
            data.conversationId,
            buyerId,
            (draft) => {
              Object.assign(draft, data);
            }
          );
        } catch {
          //
        }
      }
    })
  })
});

export type StateWithConversationApi = {
  [conversationApi.reducerPath]: ReturnType<typeof conversationApi.reducer>;
};

export const {
  useSubscribeToChatMessagesQuery,
  useFetchConversationsQuery,
  useFetchConversationQuery,
  useSendMessageMutation,
  useFetchUnreadMessagesCountQuery,
  useMarkAllMessagesReadMutation,
  useMarkMessageReadMutation
} = conversationApi;
