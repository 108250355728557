import { daAppBaseGraphqlApi } from './daAppBaseGraphqlApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Activity = {
  __typename?: 'Activity';
  cargo?: Maybe<Scalars['String']['output']>;
  type: ActivityTypeEnum;
};

export enum ActivityTypeEnum {
  Bunkering = 'BUNKERING',
  Discharging = 'DISCHARGING',
  HusbandryOwners = 'HUSBANDRY_OWNERS',
  Lightering = 'LIGHTERING',
  Loading = 'LOADING',
  Other = 'OTHER',
  Repairs = 'REPAIRS',
  ShipToShip = 'SHIP_TO_SHIP',
  Transit = 'TRANSIT'
}

export type Company = {
  __typename?: 'Company';
  name: Scalars['String']['output'];
};

export type Port = {
  __typename?: 'Port';
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  timezoneId?: Maybe<Scalars['String']['output']>;
  unlocode: Scalars['String']['output'];
};

export type PortCall = {
  __typename?: 'PortCall';
  activities: Array<Activity>;
  company: Company;
  etaDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  port: Port;
  suppliers: Suppliers;
  vessel: Vessel;
  voyage: Voyage;
};

export type Suppliers = {
  __typename?: 'Suppliers';
  names: Array<Scalars['String']['output']>;
};

export type Vessel = {
  __typename?: 'Vessel';
  commodityType: Scalars['String']['output'];
  dwt?: Maybe<Scalars['Float']['output']>;
  imo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size?: Maybe<Scalars['String']['output']>;
};

export type Voyage = {
  __typename?: 'Voyage';
  number: Scalars['String']['output'];
};

export type Query_Root = {
  __typename?: 'query_root';
  getPortCallById: PortCall;
};

export type Query_RootGetPortCallByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GetPortCallDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPortCallDetailsQuery = {
  __typename?: 'query_root';
  getPortCallById: {
    __typename?: 'PortCall';
    id: string;
    etaDate: string;
    port: {
      __typename?: 'Port';
      name: string;
      unlocode: string;
      country: string;
      timezoneId?: string | null;
    };
    vessel: {
      __typename?: 'Vessel';
      name: string;
      imo: string;
      size?: string | null;
      dwt?: number | null;
      commodityType: string;
    };
    activities: Array<{
      __typename?: 'Activity';
      type: ActivityTypeEnum;
      cargo?: string | null;
    }>;
    company: { __typename?: 'Company'; name: string };
    voyage: { __typename?: 'Voyage'; number: string };
    suppliers: { __typename?: 'Suppliers'; names: Array<string> };
  };
};

export const GetPortCallDetailsDocument = `
    query getPortCallDetails($id: ID!) {
  getPortCallById(id: $id) {
    id
    etaDate
    port {
      name
      unlocode
      country
      timezoneId
    }
    vessel {
      name
      imo
      size
      dwt
      commodityType
    }
    activities {
      type
      cargo
    }
    company {
      name
    }
    voyage {
      number
    }
    suppliers {
      names
    }
  }
}
    `;

const injectedRtkApi = daAppBaseGraphqlApi.injectEndpoints({
  endpoints: (build) => ({
    getPortCallDetails: build.query<
      GetPortCallDetailsQuery,
      GetPortCallDetailsQueryVariables
    >({
      query: (variables) => ({
        document: GetPortCallDetailsDocument,
        variables
      })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetPortCallDetailsQuery, useLazyGetPortCallDetailsQuery } =
  injectedRtkApi;
