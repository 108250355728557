import { Auth0Provider } from '@auth0/auth0-react';
import { getEnvironmentVariables, useHotjar } from '@common';
import { createPreloadedState, setupStore } from '@state';
import { UpscopeProvider } from '@upscopeio/react';
import React, { ComponentType, PropsWithChildren, useEffect } from 'react';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import {
  useAutoBlockCookies,
  useCookieConsentBanner,
  UseScriptStatus
} from '../hooks/useCookieConsentBanner';
import { useFontSize } from '../hooks/useFontSize';
import { useInspectlet } from '../hooks/useInspectlet';
import { useInitIntercom } from '../hooks/useIntercomApp';
import AuthWrapper from './AuthWrapper';

import { DrawerProvider } from '@components/Drawer/Drawer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

export const store = setupStore(createPreloadedState());
export const persistor = persistStore(store);

const {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  HEAP_API_KEY,
  INTERCOM_APP_ID,
  COOKIES_CONSENT_ENABLED
} = getEnvironmentVariables();

export const GlobalProviders = ({
  children,
  status
}: PropsWithChildren<{ status: UseScriptStatus | undefined }>): JSX.Element => {
  useCookieConsentBanner(status);
  useHotjar(status);
  useInspectlet(status);
  useInitIntercom(status);
  useFontSize();

  useEffect(() => {
    if (status === 'ready') {
      HEAP_API_KEY && window.heap?.load(HEAP_API_KEY);
    }
  }, [status]);

  const theme = createTheme({
    typography: {
      fontFamily: "'Open Sans', 'Arial', sans-serif"
    },
    palette: {
      primary: {
        main: '#0a6ebd',
        light: '#ecf6fe'
      }
    }
  });

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin + '/authorization'
      }}
    >
      <UpscopeProvider apiKey="i2qtqanZxq" enabled={status === 'ready'}>
        <AuthWrapper>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DrawerProvider>{children}</DrawerProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </PersistGate>
        </AuthWrapper>
      </UpscopeProvider>
    </Auth0Provider>
  );
};

export const withGlobalProviders = (WrappedComponent: ComponentType) => {
  const ComponentWithGlobalProviders = () => {
    const status = useAutoBlockCookies();

    return (
      <Provider store={store}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <GlobalProviders status={status}>
            <WrappedComponent />
          </GlobalProviders>
        </IntercomProvider>
      </Provider>
    );
  };

  return ComponentWithGlobalProviders;
};
