import { axiosBaseQuery } from '@client';
import { AppointmentResponse } from '@module/appointment/model/AppointmentResponse';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const appointmentApi = createApi({
  reducerPath: 'appointment-api',
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/appointment' }),
  endpoints: (build) => ({
    loadAppointment: build.query<
      AppointmentResponse,
      { appointmentId: string }
    >({
      query: ({ appointmentId }) => ({
        method: 'GET',
        url: `/${appointmentId}/load`
      })
    }),
    revertCancelledAppointment: build.mutation<
      AppointmentResponse,
      { appointmentId: number }
    >({
      query: ({ appointmentId }) => {
        return {
          url: `/${appointmentId}/revert`,
          method: 'POST'
        };
      }
    }),
    rejectAppointment: build.mutation<void, { appointmentId: string }>({
      query: ({ appointmentId }) => ({
        method: 'POST',
        url: `/${appointmentId}/reject`
      })
    }),
    closeAppointment: build.query<void, { appointmentId: string }>({
      query: ({ appointmentId }) => ({
        method: 'GET',
        url: `/${appointmentId}/close`
      })
    }),
    deleteAppointment: build.query<void, { appointmentId: number }>({
      query: ({ appointmentId }) => ({
        method: 'GET',
        url: `/${appointmentId}/delete`
      })
    })
  })
});

export const {
  useRevertCancelledAppointmentMutation,
  useRejectAppointmentMutation,
  useLazyCloseAppointmentQuery,
  useLoadAppointmentQuery,
  useLazyDeleteAppointmentQuery
} = appointmentApi;
